<template>
  <v-main class="grey lighten-3">
    <v-container>
      <v-layout row justify-center>
        <v-dialog v-model="dialog" persistent max-width="400">
          <v-card text>
            <v-card-title class="justify-center text-center text-wrap">
              <v-icon large :color="dialog_color">{{ dialog_text }}</v-icon>
            </v-card-title>
            <v-card-actions class="pa-0 ma-0">
              <v-btn color="#e41b62" block tile @click="dialog = false">{{
                "OK"
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-row justify="center" id="main">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="9"
          xl="6"
          align-self="center"
          class="px-0"
        >
          <v-card min-height="1280px">
            <v-img src="../assets/upperpart.png" contain> </v-img>

            <v-row class="px-10 mt-7">
              <v-col
                cols="12"
                sm="6"
                class="  ml-0 pr-10"
                :class="{
                  'pl-5': $vuetify.breakpoint.xsOnly,
                  'pl-16': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-card-text class="customHeadline pa-0 mb-4">
                  Do. 26.01.2023
                </v-card-text>
                <v-card-text class="customBold pa-0">
                  s.i.g. mbH Headquarter
                </v-card-text>
                <v-card-text class="pa-0">
                  Zeppelinstrasse 5/2
                </v-card-text>
                <v-card-text class="pa-0">
                  89231 Neu-Ulm
                </v-card-text>
                <v-card-text class="pa-0 mt-4">
                  <v-btn
                    text
                    color="#e41b62"
                    style="  font-size: 13pt;"
                    class="text-none linkbutton py-0"
                    href="https://www.sig-ulm.de/"
                    >www.sig-ulm.de</v-btn
                  >
                </v-card-text>
              </v-col>

              <v-col cols="12" sm="6" class="pl-0 pr-6 ml-0 pb-0">
                <v-card-text class="customHeadline  pa-0 mb-8">
                  Ihre Angaben:
                </v-card-text>

                <form>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        Firma*
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="firma"
                        required
                        @input="$v.firma.$touch()"
                        @blur="$v.firma.$touch()"
                        filled
                        outlined
                        dense
                        class="pa-0"
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        Name*
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="name"
                        required
                        @input="$v.name.$touch()"
                        @blur="$v.name.$touch()"
                        filled
                        outlined
                        dense
                        height="10"
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        Vorname*
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="vorname"
                        required
                        @input="$v.vorname.$touch()"
                        @blur="$v.vorname.$touch()"
                        filled
                        outlined
                        dense
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        Adresse
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="adresse"
                        required
                        filled
                        outlined
                        dense
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        PLZ
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="plz"
                        filled
                        outlined
                        dense
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        Ort
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="ort"
                        filled
                        outlined
                        dense
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0">
                        Email*
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="email"
                        required
                        @input="$v.email.$touch()"
                        @blur="$v.email.$touch()"
                        dense
                        filled
                        outlined
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        Telefon*
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="telefon"
                        required
                        @input="$v.telefon.$touch()"
                        @blur="$v.telefon.$touch()"
                        filled
                        outlined
                        dense
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3">
                    <v-col cols="12" sm="4" class="pa-0">
                      <v-card-text class=" pa-0 ">
                        Anmelde Code*
                      </v-card-text>
                    </v-col>
                    <v-col cols="12" sm="8" class="pa-0">
                      <v-text-field
                        v-model="regcode"
                        required
                        @input="$v.regcode.$touch()"
                        @blur="$v.regcode.$touch()"
                        filled
                        outlined
                        dense
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </form>
                <v-card-text class="customBold  pa-0 mb-6 mt-10">
                  Verbindliche Anmeldung
                </v-card-text>

                <v-card-text class="pa-0">
                  <v-row align="center" class="ma-0 mt-0 mr-0 mb-1">
                    <v-col cols="1" class=" pa-0">
                      <v-checkbox
                        class="mx-0 px-0 mt-0 py-0  justify-start"
                        v-model="checkbox_ohne"
                        color="#e41b62"
                        dense
                      ></v-checkbox
                    ></v-col>
                    <v-col cols="4" class="mx-0 px-0 pt-0 pb-2">
                      {{ "Ja, Ich komme" }}</v-col
                    >
                  </v-row>

                  <v-row class="pl-3 mt-0">
                    <v-col cols="1" class="pa-0">
                      <v-checkbox
                        class="mx-0 px-0 mt-0 py-0  justify-start"
                        v-model="checkbox_mit"
                        color="#e41b62"
                        dense
                      ></v-checkbox
                    ></v-col>
                    <v-col cols="4" class="mx-0 px-0 pt-0">
                      {{ "Ja, Ich komme mit" }}</v-col
                    >
                    <v-col cols="7" class="pa-0">
                      <v-text-field
                        v-model="person"
                        outlined
                        filled
                        dense
                        color="#e41b62"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pl-3 mt-0">
                    <v-col cols="1" class="pa-0"></v-col>
                    <v-col
                      cols="11"
                      class="mx-0 px-0 pt-0 "
                      style="color:#999791"
                    >
                      {{ "(Name der Begleitperson eintragen)" }}</v-col
                    >
                  </v-row>
                </v-card-text>
                <v-card-text class="pa-0 mx-0 mb-0 mt-4" style="color:#7b7975">
                  Alle mit “*” gekennzeichneten Felder sind Pflichtfelder.
                </v-card-text>
              </v-col>
            </v-row>

            <v-row class="px-10 mt-7">
              <v-col
                cols="12"
                sm="6"
                class="  ml-0 pr-2 mt-2"
                :class="{
                  'pl-5': $vuetify.breakpoint.xsOnly,
                  'pl-16': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-img
                  src="../assets/finger_right.png"
                  width="100px"
                  contain
                  class="ml-auto mr-2"
                >
                </v-img>
              </v-col>

              <v-col cols="12" sm="6" class="pl-0 pr-6 ml-0">
                <v-row
                  class="pt-0 mt-0"
                  :class="{
                    'justify-center': $vuetify.breakpoint.xsOnly,
                  }"
                >
                  <v-col sm="6" xs="12" class="pl-2 pr-6 ml-0 ">
                    <v-card-text
                      class="customHeadline pa-0 mb-4"
                      id="next_headline"
                    >
                      <v-btn
                        text
                        class="text-none customBoldBig ml-0 pl-0"
                        @click="sub"
                      >
                        Anmeldung absenden!
                      </v-btn>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-10 mt-4">
              <v-col
                cols="12"
                sm="6"
                class="  ml-0 pr-10"
                :class="{
                  'pl-5': $vuetify.breakpoint.xsOnly,
                  'pl-16': $vuetify.breakpoint.smAndUp,
                }"
              >
              </v-col>

              <v-col cols="12" sm="6" class="pl-0 pr-6 ml-0 pt-0">
                <v-row class="pl-0 mt-0">
                  <v-col cols="12" class="pl-2 pt-0">
                    <v-card-text
                      class="pa-0 customBold"
                      style="  font-size: 16pt; font-family: 'Times New Roman';"
                    >
                      Datenschutzerklärung
                    </v-card-text></v-col
                  >
                </v-row>

                <v-row class="pl-0 mt-0">
                  <v-col cols="12" class="pl-2 ">
                    <v-card-text class="pa-0 mb-4 text-justify">
                      Ihre im Rahmen der Anmeldung angegebenen personenbezogenen
                      Daten verwenden wir ausschließlich zum Versand der
                      Einladung an Sie.
                    </v-card-text>

                    <v-card-text class="pa-0 mb-4 text-justify">
                      Eine Weitergabe an Dritte oder anderweitige Nutzung der
                      Daten findet nicht statt. Weitere Informationen zur
                      Verarbeitung Ihrer personenbezogenen Daten können Sie
                      unserer Datenschutzerklärung entnehmen, die Sie hier
                      abrufen können:
                    </v-card-text>

                    <v-card-text class="pa-0">
                      <v-btn
                        text
                        color="#e41b62"
                        style="  font-size: 13pt;"
                        class="text-none linkbutton py-0"
                        href="https://www.sig-ulm.de/impressum/"
                        >https://www.sig-ulm.de/impressum/</v-btn
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style>
input {
  height: 8px;
}
v-card-text {
  word-break: break-word;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
}
.v-btn__content {
  letter-spacing: 0.0071428571em !important;
}
#next_headline > * {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}
.customHeadline {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}

.customBold {
  font-family: "Calibri";
  font-size: 13pt;
  font-weight: bold;
  color: #3c3c3b;
}
.v-input__slot {
  min-height: 0 !important;
  height: 30px !important;
}

.v-input--selection-controls {
  margin: 0;
  padding: 0;
}
.v-input__slot {
  border-radius: 0 !important;
}
.v-input__control {
  height: 24px;
}
.v-application {
  font-family: "Calibri";
  font-size: 13pt;
}
.linkButton > * {
  padding: 0;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],

  validations: {
    firma: { required },
    name: { required },
    vorname: { required },
    adresse: {},
    plz: {},
    ort: {},
    email: { required },
    telefon: { required },
    regcode: {
      required,
      isMatching: (value) =>
        ["Forum2023"].some((regcode) => value.indexOf(regcode) !== -1),
    },
    person: {},
  },
  data: () => ({
    firma: "",
    name: "",
    vorname: "",
    adresse: "",
    plz: "",
    ort: "",
    email: "",
    telefon: "",
    regcode: "",
    person: "",
    checkbox_ohne: false,
    checkbox_mit: false,
    dialog: false,
    dialog_text: "",
    dialog_color: "",
  }),
  methods: {
    async sub() {
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return;
      await this.submitForm();
    },
    async submitForm() {
      var formData = new FormData();
      formData.set("submitData", true);
      formData.set(
        "data",
        JSON.stringify({
          firma: this.firma,
          name: this.name,
          vorname: this.vorname,
          adresse: this.adresse,
          plz: this.plz,
          ort: this.ort,
          email: this.email,
          telefon: this.telefon,
          person: this.person,
          checkbox_ohne: this.checkbox_ohne,
          checkbox_mit: this.checkbox_mit,
        })
      );

      return this.$root.postData(formData).then((response) => {
        if (response.error) {
          this.dialog_text = "cancel";
          this.dialog_color = "red";
          this.dialog = true;
        } else {
          this.dialog_text = "done_outline";
          this.dialog_color = "green";
          this.dialog = true;
          this.firma = "";
          this.name = "";
          this.vorname = "";
          this.adresse = "";
          this.plz = "";
          this.ort = "";
          this.email = "";
          this.telefon = "";
          this.regcode = "";
          this.person = "";
          this.checkbox_ohne = false;
          this.checkbox_mit = false;
        }
      });
    },
  },
  computed: {
    firmaErrors() {
      const errors = [];
      if (!this.$v.firma.$dirty) return errors;
      !this.$v.firma.required && errors.push("Erforderliches feld");
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Erforderliches feld");
      return errors;
    },
    vorNameErrors() {
      const errors = [];
      if (!this.$v.vorname.$dirty) return errors;
      !this.$v.vorname.required && errors.push("Erforderliches feld");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Erforderliches feld");
      return errors;
    },
    telefonErrors() {
      const errors = [];
      if (!this.$v.telefon.$dirty) return errors;
      !this.$v.telefon.required && errors.push("Erforderliches feld");
      return errors;
    },
    regcodeErrors() {
      const errors = [];
      if (!this.$v.regcode.$dirty) return errors;
      !this.$v.regcode.required && errors.push("Erforderliches feld");
      return errors;
    },
  },
  mounted: function() {
    window.scrollTo(0, 0);
  },
};
</script>
