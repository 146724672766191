<template>
  <v-main class="grey lighten-3">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="6">
          <v-card min-height="1145px">
            <v-img src="../assets/upperpart.png" contain> </v-img>

            <v-row class="px-10">
              <v-col
                cols="12"
                sm="6"
                class=" mt-8 ml-0 pr-10"
                :class="{
                  'pl-5': $vuetify.breakpoint.xsOnly,
                  'pl-3': $vuetify.breakpoint.smOnly,
                  'pl-8': $vuetify.breakpoint.mdOnly,
                  'pl-8': $vuetify.breakpoint.lgOnly,
                  'pl-14': $vuetify.breakpoint.xlOnly,
                }"
              >
                <v-card-text class="customHeadline  pa-0 mb-4">
                  Agenda
                </v-card-text>
                <v-row>
                  <v-col cols="12" sm="4" class="mr-0 pr-0">
                    <v-card-text class="pa-0">
                      » 15:00 Uhr:
                    </v-card-text>
                    <v-card-text class="pa-0">
                      » 15:15 Uhr:
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" sm="8" class="mx-0 px-0">
                    <v-card-text class=" pa-0">
                      Veranstaltungsbeginn
                    </v-card-text>

                    <v-card-text class=" pa-0">
                      Begrüssung durch
                    </v-card-text>
                    <v-card-text class=" pa-0 font-weight-bold">
                      Guido Fetzer,
                    </v-card-text>
                    <v-card-text class=" pa-0">
                      s.i.g. mbH, Geschäftsleitung
                    </v-card-text>
                    <v-card-text class=" pa-0 font-weight-bold">
                      Harald Knoepfler,
                    </v-card-text>
                    <v-card-text class=" pa-0">
                      HP Deutschland GmbH
                    </v-card-text>
                    <v-card-text class=" pa-0">
                      DA PS Business
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="5" class="mr-0 pr-0">
                    <v-card-text class="pa-0">
                      » gegen 17:30 Uhr:
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" sm="7" class="mx-0 px-0">
                    <v-card-text class=" pa-0">
                      Veranstaltungsende
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6" class="pl-0 pr-6 ml-0 mt-8">
                <v-card-text class="customHeadline  pa-0 mb-9"> </v-card-text>
                <v-card-text class=" pa-0 text-justify">
                  Danach können Sie sich in gemütlicher Atmosphäre die
                  Mobility-Neuheiten von HP anschauen, sie ausprobieren und sich
                  beraten lassen. Daneben bleibt aber genügend Zeit und Raum,
                  sich mit den Teams von HP und s.i.g. mbH auszutauschen oder
                  sich Ihren Lieblingsweihnachtsbaum auszusuchen.
                </v-card-text>
                <v-card-text class=" mt-4 pa-0 text-justify">
                  Zur Stärkung zwischendurch gibt es eine Kleinigkeit zu Essen
                  und zu Trinken.
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="mt-9">
                <v-img
                  src="../assets/finger_right.png"
                  width="100px"
                  contain
                  class="ml-auto"
                >
                </v-img
              ></v-col>
              <v-col cols="12" sm="6" class="pl-0 pr-6 ml-0 mt-10">
                <v-card-text
                  class="customHeadline pa-0 mb-4"
                  id="next_headline"
                >
                  <v-btn
                    text
                    class="text-none customBoldBig ml-0 pl-0"
                    @click="next"
                  >
                    Weiter zur Anmeldung
                  </v-btn>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style>
.v-btn__content {
  letter-spacing: 0.0071428571em !important;
}
#next_headline > * {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}
.customHeadline {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}

.customBold {
  font-family: "Calibri";
  font-size: 13pt;
  font-weight: bold;
  color: #3c3c3b;
}
</style>

<script>
export default {
  data: () => ({}),
  methods: {
    next() {
      this.$router.push({
        name: "form",
        query: { redirect: "/form" },
      });
    },
  },
  mounted: function() {},
};
</script>
