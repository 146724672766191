<template>
  <v-main class="grey lighten-3">
    <v-container>
      <v-row justify="center">
        <v-col
          cols="auto"
          xs="12"
          sm="12"
          md="12"
          lg="9  "
          xl="6"
          align-self="center"
          class="px-0"
        >
          <v-card min-height="1280px">
            <v-img src="../assets/upperpart.png" contain> </v-img>

            <v-row class="px-10 mt-7">
              <v-col
                cols="12"
                sm="6"
                class="  ml-0 pr-10"
                :class="{
                  'pl-5': $vuetify.breakpoint.xsOnly,
                  'pl-16': $vuetify.breakpoint.smAndUp,
                }"
              >
                <v-card-text class="customHeadline pa-0 mb-4">
                  Agenda 26.01.2023
                </v-card-text>
                <v-card-text class=" pa-0 mb-4">
                  <span class="time font-weight-bold"> » 14:30</span> Come
                  Together
                </v-card-text>
                <v-card-text class=" pa-0 mb-4">
                  <span class="time font-weight-bold"> » 15:00 </span> Begrüßung
                  durch<br />
                  <span class="ml-3 font-weight-bold"> Herrn Eugen Straub </span
                  ><br />
                  <span class="ml-3">
                    (s.i.g. mbH, Geschäftsleitung)
                  </span>
                </v-card-text>
                <v-card-text class=" pa-0 mb-5">
                  <span class="time font-weight-bold"> » 15:10 </span
                  ><span class="font-weight-bold">
                    Managed Security Lösung:</span
                  ><br />
                  <span class="ml-3"> - Angriff & Abwehr </span><br />
                  <span class="ml-3"> - Transparenz ihrer IT Systeme </span
                  ><br />
                  <span class="ml-3">
                    - Schwachstellen u. Netzwerk-Anomalien </span
                  ><br />
                  <span class="ml-3"> - Umsetzung der Security Lösung </span
                  ><br />
                </v-card-text>

                <v-card-text class=" pa-0 mb-5">
                  <span class="ml-4 font-weight-bold"> Referenten:</span><br />
                  <span class="ml-4 font-weight-bold"> Herr Lutz Wiechmann</span
                  ><br />
                  <span class="ml-4"> (Enginsight GmbH, Sales Engineer)</span>
                </v-card-text>

                <v-card-text class=" pa-0 mb-4">
                  <span class="ml-4 font-weight-bold"> Herr Ralf Tech</span
                  ><br />
                  <span class="ml-4">
                    (s.i.g. Chief Technology Officer CTO)</span
                  >
                </v-card-text>
                <v-card-text class=" pa-0 mb-4">
                  <span class="time font-weight-bold"> » 16:30 </span>
                  Networking mit Verpflegung
                </v-card-text>
                <v-card-text class=" pa-0 mb-5">
                  <span class="time font-weight-bold">» 17:30 </span> Ende der
                  Veranstaltung
                </v-card-text>
              </v-col>

              <v-col cols="12" sm="6" class="pl-0 pr-6 ml-0">
                <v-card-text class="customHeadline pa-0 mb-5">
                  s.i.g. mbH & Enginsight GmbH: <br />
                  Managed Security Lösung für<br />
                  Ihr Unternehmen
                </v-card-text>
                <v-card-text class="customBold pa-0 mb-5 text-justify">
                  Unternehmen brauchen Cyber-Security auf höchstem Niveau – denn
                  sie werden immer häufiger Ziel von Hacker-Angriffen.
                </v-card-text>
                <v-card-text
                  class=" pa-0 text-wrap text-justify "
                  style="  word-break: break-word;"
                >
                  Gemeinsam mit unserem Partner Enginsight GmbH bieten wir
                  deshalb eine Managed Security Lösung, die optimal auf die
                  Bedürfnisse und Herausforderungen ihres Unternehmens
                  zugeschnitten sind. Im Rahmen unseres Security Forums am 26.
                  Januar 2023 wollen wir diese vorstellen und einen Einblick in
                  das Thema IT-Sicherheit geben.
                </v-card-text>
                <v-img
                  src="../assets/logo_enginsight.png"
                  width="80%"
                  contain
                  class="mt-4 mb-4"
                >
                </v-img>
                <v-card-text class=" pa-0 text-justify mb-5">
                  <v-btn
                    text
                    color="#e41b62"
                    style="  font-size: 13pt;"
                    class="text-none linkbutton py-0"
                    href="https://enginsight.com/"
                    >https://enginsight.com/</v-btn
                  >
                </v-card-text>
                <v-card-text class=" pa-0 text-justify">
                  Im Anschluss an die Vorträge bleibt Zeit, sich in gemütlicher
                  Atmosphäre und bei bester Verpflegung kennenzulernen. Hier
                  beantworten unsere Referenten auch gerne Ihre Fragen.
                </v-card-text>

                <v-card-text class="customBold pa-0 my-5">
                  Wir freuen uns auf Ihr Kommen!
                </v-card-text>
                <v-card-text class=" pa-0">
                  Ihr s.i.g. mbH Team
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class=" mt-5">
                <v-img
                  src="../assets/finger_right.png"
                  width="100px"
                  contain
                  class="ml-auto mr-2"
                >
                </v-img
              ></v-col>
              <v-col cols="12" sm="6" class="pl-0 pr-6 ml-0 mt-5">
                <v-card-text
                  class="customHeadline pa-0 mb-5"
                  id="next_headline"
                >
                  <v-btn
                    text
                    class="text-none customBoldBig ml-0 pl-0"
                    @click="next"
                  >
                    Weiter zur Anmeldung
                  </v-btn>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style>
.v-card__subtitle,
.v-card__text {
  font-size: 13pt;
}
.v-input__slot {
  border-radius: 0 !important;
}
.v-input__control {
  height: 24px;
}
.v-application {
  font-family: "Calibri";
  font-size: 13pt;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
}
.linkButton > * {
  padding: 0;
}
.time {
  color: #9b9997;
}
.v-btn__content {
  letter-spacing: 0.0071428571em !important;
}
#next_headline > * {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}
.customHeadline {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}

.customBold {
  font-family: "Calibri";
  font-size: 13pt;
  font-weight: bold;
  color: #3c3c3b;
}
</style>

<script>
export default {
  data: () => ({}),
  methods: {
    next() {
      this.$router.push({
        name: "form",
        query: { redirect: "/form" },
      });
    },
  },
  mounted: function() {},
};
</script>
