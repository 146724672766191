import Vue from "vue";
import Router from "vue-router";
import Index from "./views/Index.vue";
import Anmeldung from "./views/Anmeldung.vue";
import Agenda from "./views/Agenda.vue";
import Form from "./views/Form.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/form",
      name: "form",
      component: Form,
    },
    {
      path: "/agenda",
      name: "agenda",
      component: Agenda,
    },
    {
      path: "/",
      name: "anmeldung",
      component: Anmeldung,
    },
    {
      path: "/index",
      name: "index",
      component: Index,
    },
  ],
});
