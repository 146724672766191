<template>
  <v-main class="grey lighten-3">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" xs="12" sm="12" md="10" lg="8" xl="6">
          <v-card min-height="1145px">
            <v-img src="../assets/upperpart.png" contain class="mb-16"> </v-img>

            <v-row class="my-16 py-16">
              <v-img
                class="mt-16"
                src="../assets/finger_down.png"
                height="100px"
                contain
              >
              </v-img>
              <v-card-text
                class="customBoldBig  mb-16 pb-16 text-center"
                id="next"
              >
                <v-btn text class="text-none customBoldBig" @click="next">
                  zur Anmeldung
                </v-btn>
              </v-card-text>
              <v-card-text class="my-16 pb-16 text-center">
                <v-btn
                  href="https://www.sig-erfurt.de/impressum/"
                  text
                  class="text-none "
                >
                  {{ "»" + " " + "Datenschutzerklärung" }}
                </v-btn>
              </v-card-text>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style>
.v-btn__content {
  letter-spacing: 0.0071428571em !important;
}
#next > * {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
}
.customBoldBig {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #3c3c3b;
}
</style>

<script>
export default {
  data: () => ({}),
  methods: {
    next() {
      this.$router.push({
        name: "anmeldung",
        query: { redirect: "/anmeldung" },
      });
    },
  },
  mounted: function() {},
};
</script>
