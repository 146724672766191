<template>
  <v-app style="background-color: #f2f5f8">
    <router-view> </router-view>
  </v-app>
</template>

<style>
.v-card__subtitle,
.v-card__text {
  font-size: 13pt;
}
.v-input__slot {
  border-radius: 0 !important;
}
.v-input__control {
  height: 24px;
}
.v-application {
  font-family: "Calibri";
  font-size: 13pt;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
}
.linkButton > * {
  padding: 0;
}
.time {
  color: #9b9997;
}
.v-btn__content {
  letter-spacing: 0.0071428571em !important;
}
#next_headline > * {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}
.customHeadline {
  font-family: "Times New Roman";
  font-size: 16pt;
  font-weight: bold;
  color: #e41b62;
}

.customBold {
  font-family: "Calibri";
  font-size: 13pt;
  font-weight: bold;
  color: #3c3c3b;
}
</style>

<script>
import RegForm from "./components/RegForm.vue";
export default {
  name: "App",

  components: { RegForm },

  data: () => ({
    drawer: true,
  }),
};
</script>
