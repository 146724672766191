<template>
  <v-main>
    <v-container fluid fill-height style="background-color: #f2f5f8">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 class="text-xs-center">
          <v-alert :value="error" type="error">{{ errorMessage }}</v-alert>
          <v-card class="pa-2">
            <v-card-text>
              <form>
                <v-text-field
                  v-model="name"
                  :error-messages="nameErrors"
                  :counter="10"
                  label="Name"
                  required
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  label="E-mail"
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                ></v-text-field>
                <v-btn color="primary" block @click="sub">
                  {{ "Submit" }}
                </v-btn>
              </form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
export default {
  name: "RegForm",
  props: {},
  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(10) },
    email: { required, email },
  },
  data: () => ({
    name: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    age: "",
    address: "",
    valid: true,
    error: false,
    errorMessage: false,
    rules_string: {
      password: (value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return (
          (pattern.test(value) && value.trim() !== "") ||
          "Min. 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character:"
        );
      },
    },
  }),
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    isPasswordValid() {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        this.password
      );
    },
    headers_items() {
      return [
        { text: "SAP_NR", value: "sap_nr", sortable: true },
        { text: "Name", value: "name", sortable: true },
        { text: "Unit price", value: "price", sortable: true },
      ];
    },
  },
  methods: {
    sub() {
      this.$v.$touch();
      console.log("asd");
    },
    async submit() {
      var formData = new FormData();
      formData.set("submitData", true);
      formData.set("name", this.name);
      formData.set("email", this.email);
      formData.set("address", this.address);

      this.$root.postData(formData).then((response) => {
        console.log(response);
      });
    },
    async login() {
      console.log("test");
    },
    async changePassword() {
      var data = {
        method: "changePassword",
        customer_id: this.customer_id,
        password: this.password,
      };

      return this.$root.postData(data).then((response) => {});
    },
  },

  mounted() {},
};
</script>
