import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import router from "./router";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import axios from "axios";
import VueAxios from "vue-axios";

import tz from "moment-timezone";
import VueColumnsResizableVuetify from "./../node_modules/vue-columns-resizable-vuetify";

import JsonExcel from "vue-json-excel";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import Vuelidate from "vuelidate";

import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

// use the APIs
import { ref, reactive } from "@vue/composition-api";

Vue.component("downloadExcel", JsonExcel);

const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

Vue.use(VueColumnsResizableVuetify);

Vue.use(require("vue-moment"), {
  moment,
  tz,
});

Vue.use(require("vue-moment"));
require("moment/locale/de");

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  data: {},
  methods: {
    async postData(formData) {
      const response = await this.networkLoop(formData);

      return response;
    },

    async networkLoop(formData) {
      const response = await this.axios
        .post("/server.php", formData, {})
        .then(function(response) {
          return Promise.resolve(response.data);
        })
        .catch(function(error) {
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
          return Promise.reject(error.response);
        })
        .finally(function() {});

      return response;
    },
  },
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
